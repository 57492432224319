const DownloadPDF = () => {
    return (
        <>
        <div sx={{ cursor: "pointer"}}>
            <a style={{color: "#fff", textDecoration: 'none'}} target='_blank' href='./documentation/MSM_Documentation.pdf'>HELP</a>
        </div>
        </>
    )
}

export default DownloadPDF
